/*eslint-disable*/
import React from 'react';
import './DarkFooter.css';

function DarkFooter() {
  return (
    <div className='footer-wrapper'>
      <footer className='footer' data-background-color='black'>
        <div className='footer-inner'>
          <nav>
            <ul>
              <li>
                <a href='/about'>About Us</a>
              </li>
              <li>
                <a href='/search-certificate'>Search Certificate</a>
              </li>
            </ul>
          </nav>
          <div className='copyright' id='copyright'>
            © {new Date().getFullYear()} - Wanncert International Certification
          </div>
        </div>
      </footer>
    </div>
  );
}

export default DarkFooter;
