import React from 'react';

import CertificateNavbar from 'components/Navbars/CertificateNavbar';
import DarkFooter from 'components/Footers/DarkFooter';
import CarouselSection from 'views/Carousel';
import './LandingPage.css';

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <>
      <div className='main'>
        <CertificateNavbar />
        <div className='section' id='carousel'>
          <CarouselSection />
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
