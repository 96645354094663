import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Table,
} from 'reactstrap';
import CertificateNavbar from 'components/Navbars/CertificateNavbar';
import DarkFooter from 'components/Footers/DarkFooter';
import './CertificatePage.css';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseAnonKey);

function CertificatePage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certificateNumber, setCertificateNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  useEffect(() => {
    setHasError(false);
  }, [certificateNumber, companyName]);

  async function fetchCertificates() {
    setHasError(false);
    const trimmedCertificateNumber = certificateNumber.trim();
    const companyInput = companyName.trim();

    if (!trimmedCertificateNumber || !companyInput || companyInput.length < 3) {
      return;
    }

    let query = supabase.from('certificates').select('*');

    query = query
      .eq('certificate_number', trimmedCertificateNumber)
      .ilike('company', `${companyInput}%`);

    let { data, error } = await query;

    if (error || !data || data.length === 0) {
      setHasError(true);
      console.error(error || 'No data found.');
      setCertificates([]);
    } else {
      setCertificates(data);
      setCertificateNumber('');
      setCompanyName('');
    }
  }

  return (
    <>
      <CertificateNavbar />
      <div className='certificate-wrapper'>
        <div className='section section-contact-us text-center'>
          <Container className='wrapper-container'>
            <h2 className='page-title'>Search Certificate</h2>
            <p className='description'>Find your certification details</p>
            <Row className='search-area'>
              <Col className='text-center ml-auto mr-auto' lg='6' md='8'>
                <InputGroup
                  className={
                    'input-lg input-group-custom' +
                    (firstFocus ? ' input-group-focus' : '')
                  }
                >
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText />
                  </InputGroupAddon>
                  <Input
                    placeholder='Certificate Number'
                    type='text'
                    value={certificateNumber}
                    onChange={(e) => setCertificateNumber(e.target.value)}
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  />
                </InputGroup>
                <InputGroup
                  className={
                    'input-lg input-group-custom' +
                    (lastFocus ? ' input-group-focus' : '')
                  }
                >
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText />
                  </InputGroupAddon>
                  <Input
                    placeholder='Company Name'
                    type='text'
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  />
                </InputGroup>
                <div className='send-button'>
                  <Button
                    block
                    className='btn-round'
                    color='info'
                    href='#pablo'
                    onClick={(e) => {
                      e.preventDefault();
                      fetchCertificates();
                    }}
                    size='lg'
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
            {certificates.length !== 0 && !hasError && (
              <Row className='tableRow'>
                <Col className='ml-auto mr-auto' lg='10' md='12'>
                  <Table>
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Certificate Name</th>
                        <th>Validity Date</th>
                        <th>Company Base</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificates.map((cert) => (
                        <tr key={cert.id}>
                          <td>{cert.company}</td>
                          <td>{cert.certificate_name}</td>
                          <td>{cert.expire_date}</td>
                          <td>{cert.company_base}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {hasError && (
              <div class='alert alert-danger' role='alert'>
                No certificate found.
              </div>
            )}
          </Container>
        </div>
      </div>
      <div className='footer-container'>
        <DarkFooter />
      </div>
    </>
  );
}

export default CertificatePage;
