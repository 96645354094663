import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// styles for this kit
import 'assets/css/bootstrap.min.css';
import 'assets/scss/now-ui-kit.scss?v=1.5.0';
import 'assets/demo/demo.css?v=1.5.0';
// pages for this kit
import LandingPage from 'views/LandingPage';
import CertificatePage from 'views/CertificatePage';
import AboutUsPage from 'views/About';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/search-certificate' element={<CertificatePage />} />
      <Route path='/about' element={<AboutUsPage />} />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  </BrowserRouter>
);
