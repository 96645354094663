import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CertificateNavbar from 'components/Navbars/CertificateNavbar';
import DarkFooter from 'components/Footers/DarkFooter';
import aboutUsImage from '../assets/img/wanncert-image-1.jpg';
import './About.css';

function AboutUsPage() {
  useEffect(() => {
    document.body.classList.add('about-page');
    document.body.classList.add('sidebar-collapse');
    window.scrollTo(0, 0);
    return () => {
      document.body.classList.remove('about-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  return (
    <>
      <CertificateNavbar />
      <div className='about-wrapper'>
        <div className='section text-center about-section'>
          <Container className='content-container'>
            <h2 className='about-page-title'>About</h2>
            <Row className='about-us-content align-items-center'>
              <Col lg='6' md='12' className='image-section'>
                <img
                  src={aboutUsImage}
                  alt='About Us'
                  className='img-fluid about-img'
                />
              </Col>
              <Col lg='6' md='12' className='about-text-section'>
                <p className='about-text'>
                  Wanncert International Certification began its certification
                  activities in 2017.
                </p>
                <p className='about-text'>
                  It operates in the fields of international system
                  certification, product certification, CE marking, and
                  training, in line with its independence and confidentiality
                  policies.
                </p>
                <p className='about-text'>
                  Wanncert International Certification is a trustworthy
                  organization with no other solution partners, staffed with
                  competent auditors and technical experts.
                </p>
                <p className='about-text'>
                  By prioritizing customer satisfaction through the quality of
                  its certification services, the organization continues to
                  strengthen its position in the sector and grows steadily with
                  each passing day.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='about-footer-container'>
        <DarkFooter />
      </div>
    </>
  );
}

export default AboutUsPage;
