import React from 'react';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './CertificateNavbar.css'; // Import the CSS file

function CertificateNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [systemDropdownOpen, setSystemDropdownOpen] = React.useState(false);
  const [productDropdownOpen, setProductDropdownOpen] = React.useState(false);

  const toggleSystemDropdown = () => setSystemDropdownOpen(!systemDropdownOpen);
  const toggleProductDropdown = () =>
    setProductDropdownOpen(!productDropdownOpen);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={`fixed-top navbar-custom `} expand='lg'>
        <Container>
          <div className='navbar-translate'>
            <NavbarBrand href='/' id='navbar-brand'>
              <img
                alt='Logo'
                src={require('assets/img/wanncert-logo.png')}
                style={{ height: '50px' }}
              />
            </NavbarBrand>
            <button
              className='navbar-toggler navbar-toggler'
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type='button'
            >
              <span className='navbar-toggler-bar top-bar'></span>
              <span className='navbar-toggler-bar middle-bar'></span>
              <span className='navbar-toggler-bar bottom-bar'></span>
            </button>
          </div>
          <Collapse
            className='justify-content-end'
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href='/about'>
                  <p>About Us</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={systemDropdownOpen}
                  toggle={toggleSystemDropdown}
                  nav
                >
                  <DropdownToggle nav caret>
                    <p>System Certification</p>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href='#'>ISO 9001</DropdownItem>
                    <DropdownItem href='#'>ISO 10002</DropdownItem>
                    <DropdownItem href='#'>ISO 13485</DropdownItem>
                    <DropdownItem href='#'>ISO 14001</DropdownItem>
                    <DropdownItem href='#'>ISO 22000</DropdownItem>
                    <DropdownItem href='#'>ISO 27001</DropdownItem>
                    <DropdownItem href='#'>ISO 45001</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <Dropdown
                  isOpen={productDropdownOpen}
                  toggle={toggleProductDropdown}
                  nav
                >
                  <DropdownToggle nav caret>
                    <p>Product Certification</p>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href='#'>CE MARKING</DropdownItem>
                    <DropdownItem href='#'>OHSAS 18001</DropdownItem>
                    <DropdownItem href='#'>HALAL CERTIFICATION</DropdownItem>
                    <DropdownItem href='#'>SEDEX CERTIFICATION</DropdownItem>
                    <DropdownItem href='#'>BRC CERTIFICATION</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <NavLink href='/search-certificate'>
                  <p>Search Certificate</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CertificateNavbar;
